.main-header {
  @apply bg-zz-blue;

  transition: box-shadow .2s;

  position: sticky;
  top: 0;
  z-index: 999;

  .content {
    padding:30px 0;
    transition: all .2s;

    display: flex;
    justify-content: space-between;

    .left {
      max-width: 60vw;
      img {
        min-height: 30px;
        max-width: 350px;
        width: 100%;
      }
    }

    .right {
      @apply hidden lg:flex;

      align-items: center;

      .menu {
        display: flex;
        margin: 0;
        padding: 0;

        li {
          margin-left: 30px;

          a {
            font-weight: bold;
            @apply text-zz-blue-dark
          }
        }
      }
    }
  }

  &[data-has-scrolled="true"] {
    .content {
      padding: 15px 0;
    }

    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }
}