.waarom_smaakonderzoek {
  @apply text-zz-green-dark;

  .waarom-items {
    @apply gap-8;

    .waarom-item {

      .inner {
        @apply bg-zz-green rounded-3xl;
        padding: 35px;
      }
    }
  }
}